<template>
  <div class="contact">
    <top-panel></top-panel>
    <cookie-panel></cookie-panel>
    <div class="contact--container">
      <div class="global--section">
        <div class="global--section_container">
          <div class="global--title_container">
            <div class="global--title global--title-small">Contact</div>
            <div class="global--title_border"></div>
            <div class="global--animation_overlay global--animation_delay-basic" v-in-viewport.once></div>
          </div>
          <div class="global--title global--title-large global--animation_reveal" v-in-viewport.once>Let's discuss <br>your project.</div>
          <div class="global--container global--container-right">
            <div class="global--text_container global--animation_reveal" v-in-viewport.once>
              <div class="global--text">We are always happy to talk with you. Feel free to reach us in any fitting way.</div>
              <a class="contact--link global--link" href="mailto:hello@faircode.tech">hello@faircode.tech</a>
            </div>
          </div>
        </div>
      </div>
      <div class="global--section">
        <div class="global--section_container">
          <div class="contact--form_holder">
            <div class="contact--form_container">
              <form class="contact--form" novalidate v-on:submit.prevent="processForm">
                <input class="contact--form_submit" type="submit">
                <div class="contact--form_section_holder">
                  <div class="contact--form_section">
                    <div class="contact--form_title">What are you looking to work on?</div>
                    <div class="contact--form_options">
                      <div class="contact--form_option_item">
                        <div class="contact--form_option_container">
                          <input class="contact--form_option_input" id="category[0]" type="checkbox" value="web" v-model="categoryArray">
                          <label class="contact--form_option_label" for="category[0]">Web Development</label>
                        </div>
                      </div>
                      <div class="contact--form_option_item">
                        <div class="contact--form_option_container">
                          <input class="contact--form_option_input" id="category[1]" type="checkbox" value="mobile" v-model="categoryArray">
                          <label class="contact--form_option_label" for="category[1]">Mobile Development</label>
                        </div>
                      </div>
                      <div class="contact--form_option_item">
                        <div class="contact--form_option_container">
                          <input class="contact--form_option_input" id="category[2]" type="checkbox" value="api" v-model="categoryArray">
                          <label class="contact--form_option_label" for="category[2]">API Development</label>
                        </div>
                      </div>
                      <div class="contact--form_option_item">
                        <div class="contact--form_option_container">
                          <input class="contact--form_option_input" id="category[3]" type="checkbox" value="brand" v-model="categoryArray">
                          <label class="contact--form_option_label" for="category[3]">Branding</label>
                        </div>
                      </div>
                      <div class="contact--form_option_item">
                        <div class="contact--form_option_container">
                          <input class="contact--form_option_input" id="category[4]" type="checkbox" value="logo" v-model="categoryArray">
                          <label class="contact--form_option_label" for="category[4]">Logo Design</label>
                        </div>
                      </div>
                      <div class="contact--form_option_item">
                        <div class="contact--form_option_container">
                          <input class="contact--form_option_input" id="category[5]" type="checkbox" value="ux" v-model="categoryArray">
                          <label class="contact--form_option_label" for="category[5]">UI & UX Design</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="global--animation_overlay" v-in-viewport.once></div>
                </div>
                <div class="contact--form_section_holder">
                  <div class="contact--form_section">
                    <div class="contact--form_title">What is your budget range for this project?</div>
                    <div class="contact--form_options">
                      <div class="contact--form_option_item">
                        <div class="contact--form_option_container">
                          <input class="contact--form_option_input" id="budget[0]" type="radio" name="budget" value="0" v-model="selectedBudget">
                          <label class="contact--form_option_label" for="budget[0]">$1,000 - $5,000</label>
                        </div>
                      </div>
                      <div class="contact--form_option_item">
                        <div class="contact--form_option_container">
                          <input class="contact--form_option_input" id="budget[1]" type="radio" name="budget" value="1" v-model="selectedBudget">
                          <label class="contact--form_option_label" for="budget[1]">$5,000 - $10,000</label>
                        </div>
                      </div>
                      <div class="contact--form_option_item">
                        <div class="contact--form_option_container">
                          <input class="contact--form_option_input" id="budget[2]" type="radio" name="budget" value="2" v-model="selectedBudget">
                          <label class="contact--form_option_label" for="budget[2]">$10,000 - $20,000</label>
                        </div>
                      </div>
                      <div class="contact--form_option_item">
                        <div class="contact--form_option_container">
                          <input class="contact--form_option_input" id="budget[3]" type="radio" name="budget" value="3" v-model="selectedBudget">
                          <label class="contact--form_option_label" for="budget[3]">$20,000 - $50,000</label>
                        </div>
                      </div>
                      <div class="contact--form_option_item">
                        <div class="contact--form_option_container">
                          <input class="contact--form_option_input" id="budget[4]" type="radio" name="budget" value="4" v-model="selectedBudget">
                          <label class="contact--form_option_label" for="budget[4]">$50,000 - $100,000</label>
                        </div>
                      </div>
                      <div class="contact--form_option_item">
                        <div class="contact--form_option_container">
                          <input class="contact--form_option_input" id="budget[5]" type="radio" name="budget" value="5" v-model="selectedBudget">
                          <label class="contact--form_option_label" for="budget[5]">More than $100,000</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="global--animation_overlay" v-in-viewport.once></div>
                </div>
                <div class="contact--form_section_holder">
                  <div class="contact--form_section contact--form_section-plain">
                    <div class="contact--form_input_container">
                      <label class="contact--form_label" for="first_name">First Name:</label>
                      <input class="contact--form_input" id="first_name" type="text" placeholder="Robert W." v-model="firstNameInput">
                    </div>
                  </div>
                  <div class="global--animation_overlay" v-in-viewport.once></div>
                </div>
                <div class="contact--form_section_holder">
                  <div class="contact--form_section contact--form_section-plain">
                    <div class="contact--form_input_container">
                      <label class="contact--form_label" for="last_name">Last Name:</label>
                      <input class="contact--form_input" id="last_name" type="text" placeholder="Smith" v-model="lastNameInput">
                    </div>
                  </div>
                  <div class="global--animation_overlay" v-in-viewport.once></div>
                </div>
                <div class="contact--form_section_holder">
                  <div class="contact--form_section contact--form_section-plain">
                    <div class="contact--form_input_container">
                      <label class="contact--form_label" for="company">Company:</label>
                      <input class="contact--form_input" id="company" type="text" placeholder="FairCode Ltd" v-model="companyInput">
                    </div>
                  </div>
                  <div class="global--animation_overlay" v-in-viewport.once></div>
                </div>
                <div class="contact--form_section_holder">
                  <div class="contact--form_section contact--form_section-plain">
                    <div class="contact--form_input_container">
                      <label class="contact--form_label" for="email">Email:</label>
                      <input class="contact--form_input" id="email" type="email" inputmode="email" placeholder="robert@example.com" v-model="emailInput">
                    </div>
                  </div>
                  <div class="global--animation_overlay" v-in-viewport.once></div>
                </div>
                <div class="contact--form_section_holder">
                  <div class="contact--form_section contact--form_section-plain">
                    <div class="contact--form_input_container">
                      <label class="contact--form_label" for="deadline">Deadline:</label>
                      <input class="contact--form_input" id="deadline" type="text" placeholder="1 Month" v-model="deadlineInput">
                    </div>
                  </div>
                  <div class="global--animation_overlay" v-in-viewport.once></div>
                </div>
                <div class="contact--form_section_holder">
                  <div class="contact--form_section contact--form_section-plain">
                    <div class="contact--form_textarea_container">
                      <label class="contact--form_label contact--form_label-full" for="description">Description:</label>
                      <textarea class="contact--form_textarea" id="description" placeholder="Highlight your project details" v-model="descriptionInput"/>
                    </div>
                  </div>
                  <div class="global--animation_overlay" v-in-viewport.once></div>
                </div>
                <div class="contact--form_section_holder">
                  <div class="contact--form_submit_container global--animation_reveal" v-in-viewport.once>
                    <div class="contact--form_notice">By clicking the button, you<br>agree to our <a class="contact--form_notice_link" href="/privacy" target="_blank">privacy policy</a>.</div>
                    <button class="contact--form_button" :class="{ 'contact--form_button-disabled': isDisabled || isProcessing }">Send</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="global--section">
        <div class="global--section_container">
          <div class="global--title_container" v-in-viewport.once>
            <div class="global--title global--title-small">Process</div>
            <div class="global--title_border"></div>
            <div class="global--animation_overlay global--animation_delay-basic" v-in-viewport.once></div>
          </div>
          <div class="global--animation_container">
            <div class="global--title global--title-middle">What happens after <br>you contact us?</div>
            <div class="global--animation_overlay" v-in-viewport.once></div>
          </div>
          <div class="global--matter_holder">
            <div class="global--matter_container global--matter_container-placeholder"></div>
            <div class="global--matter_container">
              <div class="global--matter_number">01.</div>
              <div class="global--matter_text">Once you have contacted us, we will reach out to you and find a good time and place to discuss your challenge and goals in details.</div>
              <div class="global--animation_overlay" v-in-viewport.once></div>
            </div>
            <div class="global--matter_container">
              <div class="global--matter_number">02.</div>
              <div class="global--matter_text">We provide you with a detailed project estimation (services required, price, duration, team composition).</div>
              <div class="global--animation_overlay" v-in-viewport.once></div>
            </div>
            <div class="global--matter_container">
              <div class="global--matter_number">03.</div>
              <div class="global--matter_text">We are ready to start working with you once we agree on the proposal and sign the contract.</div>
              <div class="global--animation_overlay" v-in-viewport.once></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom-panel></bottom-panel>
  </div>
</template>

<script>
  import TopPanel from '@/components/top_panel/TopPanel'
  import BottomPanel from '@/components/bottom_panel/BottomPanel'
  import CookiePanel from '@/components/cookie_panel/CookiePanel'

  export default {
    name: 'Contact',
    data () {
      return {
        categoryArray: [],
        selectedBudget: null,
        firstNameInput: '',
        lastNameInput: '',
        companyInput: '',
        emailInput: '',
        deadlineInput: '',
        descriptionInput: ''
      }
    },
    components: {
      TopPanel,
      BottomPanel,
      CookiePanel
    },
    computed: {
      isProcessing () {
        return this.$store.state.contact.isProcessing
      },
      isDisabled () {
        if (this.categoryArray.length <= 0) {
          return true
        }

        if (this.selectedBudget === null) {
          return true
        }

        if (this.firstNameInput.length === 0) {
          return true
        }

        if (this.lastNameInput.length === 0) {
          return true
        }

        if (this.companyInput.length === 0) {
          return true
        }

        if (this.emailInput.length === 0) {
          return true
        }

        if (this.deadlineInput.length === 0) {
          return true
        }

        return this.descriptionInput.length === 0
      }
    },
    methods: {
      processForm () {
        this.$store.dispatch('sendForm', { services: this.categoryArray, budget: this.selectedBudget, firstName: this.firstNameInput, lastName: this.lastNameInput, companyName: this.companyInput, email: this.emailInput, deadline: this.deadlineInput, description: this.descriptionInput }).then(() => {
          this.categoryArray = []
          this.selectedBudget = null
          this.firstNameInput = ''
          this.lastNameInput = ''
          this.companyInput = ''
          this.emailInput = ''
          this.deadlineInput = ''
          this.descriptionInput = ''
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import './contact';
</style>
