<template>
  <div class="contact">
    <top-panel></top-panel>
    <cookie-panel></cookie-panel>
    <div class="auth--container">
      <div class="global--section">
        <div class="global--section_container">
          <div class="global--title_container">
            <div class="global--title global--title-small">Client Access</div>
            <div class="global--title_border"></div>
            <div class="global--animation_overlay global--animation_delay-basic" v-in-viewport.once></div>
          </div>
          <div class="global--title global--title-large global--animation_reveal" v-in-viewport.once>Project <br>Overview</div>
        </div>
      </div>
      <div class="global--section">
        <div class="global--section_container auth--section_container">
          <div class="auth--form_holder">
            <div class="auth--form_container">
              <form class="auth--form" novalidate v-on:submit.prevent="processForm">
                <input class="auth--form_submit" type="submit">
                <div class="auth--form_section_holder">
                  <div class="auth--form_section auth--form_section-plain">
                    <div class="auth--form_input_container">
                      <label class="auth--form_label" for="first_name">Email:</label>
                      <input class="auth--form_input" id="first_name" type="text" placeholder="you@mail.com" v-model="email">
                    </div>
                  </div>
                  <div class="global--animation_overlay" v-in-viewport.once></div>
                </div>
                <div class="auth--form_section_holder">
                  <div class="auth--form_submit_container global--animation_reveal" v-in-viewport.once>
                    <div class="auth--form_notice">By clicking the button, you<br>agree to our <a class="auth--form_notice_link" href="/privacy" target="_blank">privacy policy</a>.</div>
                    <button class="auth--form_button" :class="{ 'auth--form_button-disabled': isDisabled || isProcessing }">Log In</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom-panel></bottom-panel>
  </div>
</template>

<script>
  import TopPanel from '@/components/top_panel/TopPanel'
  import BottomPanel from '@/components/bottom_panel/BottomPanel'
  import CookiePanel from '@/components/cookie_panel/CookiePanel'

  export default {
    name: 'Contact',
    data () {
      return {
        email: ''
      }
    },
    components: {
      TopPanel,
      BottomPanel,
      CookiePanel
    },
    computed: {
      isProcessing () {
        return this.$store.state.contact.isProcessing
      },
      isDisabled () {
        return this.email.length === 0
      }
    },
    methods: {
      processForm () {
        this.$store.dispatch('authenticate', { email: this.email }).then(() => {
          this.email = ''
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'auth';
</style>
