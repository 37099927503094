<template>
  <div class="about">
    <top-panel></top-panel>
    <cookie-panel></cookie-panel>
    <div class="about--container">
      <div class="global--section">
        <div class="global--section_container">
          <div class="global--title_container">
            <div class="global--title global--title-small">About</div>
            <div class="global--title_border"></div>
            <div class="global--animation_overlay global--animation_delay-basic" v-in-viewport.once></div>
          </div>
          <div class="global--title global--title-large global--animation_reveal" v-in-viewport.once>We design creative solutions that engage your customers.</div>
        </div>
      </div>
      <div class="global--section">
        <div class="global--section_container">
          <div class="global--title_container">
            <div class="global--title global--title-small">Team</div>
            <div class="global--title_border"></div>
            <div class="global--animation_overlay global--animation_delay-basic" v-in-viewport.once></div>
          </div>
          <div class="about--team_title global--title global--title-middle global--animation_reveal" v-in-viewport.once>We are a group of talented people, working side by side committed to delivering an excellent digital experience.</div>
          <div class="global--container global--container-right">
            <div class="about--team_holder">
              <div class="about--team_container global--animation_container">
                <div class="about--team_text global--text">FairCode is a passionate, highly skilled team of IT professionals who work around the clock to make sure that your project will run smoothly like a butter.</div>
                <div class="global--animation_overlay" v-in-viewport.once></div>
              </div>
              <div class="about--team_container global--animation_container">
                <div class="about--team_text global--text">Unlike others, we go straight ahead and accept the most challenging missions on our shared digital adventure.</div>
                <div class="global--animation_overlay" v-in-viewport.once></div>
              </div>
              <div class="about--team_container global--animation_container">
                <div class="about--team_text global--text">We love what we do, and that allows us to make a truly great work that holds meaning and produces a positive impact on the world around us.</div>
                <div class="global--animation_overlay" v-in-viewport.once></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="global--section">
        <div class="global--section_container">
          <div class="global--title_container">
            <div class="global--title global--title-small">Leadership</div>
            <div class="global--title_border"></div>
            <div class="global--animation_overlay global--animation_delay-basic" v-in-viewport.once></div>
          </div>
          <div class="about--team_title global--title global--title-middle global--animation_reveal" v-in-viewport.once>Together, we set high standards <br>of responsibility and ethics.</div>
          <div class="about--person_holder global--animation_reveal" v-in-viewport.once>
            <div class="about--person_container global--animation_container">
              <div class="about--person_title">Petr Bieľák</div>
              <div class="about--person_occupation">Founder</div>
              <a class="about--person_link" href="https://linkedin.com/in/peterbielak" target="_blank">@PeterBielak</a>
            </div>
            <div class="about--person_container global--animation_container">
              <div class="about--person_title">Daniel Kubíček</div>
              <div class="about--person_occupation">Mobile Developer</div>
              <a class="about--person_link" href="https://www.linkedin.com/in/danielkubicek/" target="_blank">@DanielKubicek</a>
            </div>
          </div>
        </div>
      </div>
      <div class="global--section">
        <div class="global--section_container">
          <div class="global--title_container">
            <div class="global--title global--title-small">Corporate Information</div>
            <div class="global--title_border"></div>
            <div class="global--animation_overlay global--animation_delay-basic" v-in-viewport.once></div>
          </div>
          <div class="about--team_title global--title global--title-middle global--animation_reveal" v-in-viewport.once>Important details about <br>our company</div>
          <div class="about--company_details_holder global--animation_reveal" v-in-viewport.once>
            <div class="about--company_detail_container">
              <div class="about--company_value_container">
                <div class="about--company_title">Headquarters</div>
                <div class="about--company_value">Kemp House</div>
                <div class="about--company_value">160 City Road</div>
                <div class="about--company_value">London EC1V 2NX</div>
                <div class="about--company_value">United Kingdom</div>
              </div>
            </div>
            <div class="about--company_detail_container">
              <div class="about--company_value_container">
                <div class="about--company_title">Companies House</div>
                <div class="about--company_value">FAIRCODE LTD</div>
                <div class="about--company_value">Registration No. 11382758</div>
                <div class="about--company_value">Registered in England and Wales</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom-panel></bottom-panel>
  </div>
</template>

<script>
  import TopPanel from '@/components/top_panel/TopPanel'
  import BottomPanel from '@/components/bottom_panel/BottomPanel'
  import CookiePanel from '@/components/cookie_panel/CookiePanel'

  export default {
    name: 'About',
    components: {
      TopPanel,
      BottomPanel,
      CookiePanel
    }
  }
</script>

<style lang="scss" scoped>
  @import './about';
</style>
