<template>
  <div class="services">
    <top-panel></top-panel>
    <cookie-panel></cookie-panel>
    <div class="services--container">
      <div class="global--section">
        <div class="global--section_container">
          <div class="global--title_container">
            <div class="global--title global--title-small">Services</div>
            <div class="global--title_border"></div>
            <div class="global--animation_overlay global--animation_delay-basic" v-in-viewport.once></div>
          </div>
          <div class="global--title global--title-large global--animation_reveal" v-in-viewport.once>We provide a complete workflow from idea to production deployment.</div>
        </div>
      </div>
      <div class="global--section">
        <div class="global--section_container">
          <div class="global--title_container" v-in-viewport.once>
            <div class="global--title global--title-small">Platforms</div>
            <div class="global--title_border"></div>
            <div class="global--animation_overlay global--animation_delay-basic" v-in-viewport.once></div>
          </div>
          <div class="global--animation_container">
            <div class="global--title global--title-middle">We cover all major operating <br>systems and environments.</div>
            <div class="global--animation_overlay" v-in-viewport.once></div>
          </div>
          <div class="global--matter_holder">
            <div class="global--matter_container global--matter_container-placeholder"></div>
            <div class="global--matter_container">
              <div class="global--matter_number">01.</div>
              <div class="global--matter_title">Web</div>
              <div class="global--matter_text">The web interface has become a fundamental part of the digital ecosystem, and it continues to evolve every day.</div>
              <div class="global--animation_overlay" v-in-viewport.once></div>
            </div>
            <div class="global--matter_container">
              <div class="global--matter_number">02.</div>
              <div class="global--matter_title">Mobile</div>
              <div class="global--matter_text">We create applications using native programming languages to ensure the highest stability and optimization.</div>
              <div class="global--animation_overlay" v-in-viewport.once></div>
            </div>
            <div class="global--matter_container">
              <div class="global--matter_number">03.</div>
              <div class="global--matter_title">Server</div>
              <div class="global--matter_text">APIs and underlying server infrastructure is the core of your business, and everything else depends on it.</div>
              <div class="global--animation_overlay" v-in-viewport.once></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom-panel></bottom-panel>
  </div>
</template>

<script>
  import TopPanel from '@/components/top_panel/TopPanel'
  import BottomPanel from '@/components/bottom_panel/BottomPanel'
  import CookiePanel from '@/components/cookie_panel/CookiePanel'

  export default {
    name: 'Services',
    components: {
      TopPanel,
      BottomPanel,
      CookiePanel
    }
  }
</script>

<style lang="scss" scoped>
  @import './services';
</style>
